@import '~/styles/styles';

.gradientbox-parent-container {
  position: relative;
  z-index: 1;
}

.gradientbox-container {
  position: relative;
  display: flex;
  place-content: space-around;
  border-radius: 10px;
  background: $black-900;
  padding: 30px;

  @include tablet {
    padding: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: -1px;
    background: $gradient-rainbow-dark;
    border-radius: inherit;
    z-index: -1;
  }
}

@include mobile {
  .gradientbox-container {
    flex-direction: column;
  }
}
