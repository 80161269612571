a {
  cursor: pointer;
  text-decoration: none;
  color: $gray;
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    color: $white;
  }
}

button {
  padding: 10px 30px;
  text-align: center;
  font-weight: 900;
  border-radius: $border-radius;
  background: $gradient-purple;
  background-size: 185% 100%;
  border: none;
  color: $white;
  cursor: pointer;
  min-width: 160px;
  transition: all 500ms 300ms cubic-bezier(0.55, 0.27, 0.4, 0.89);

  @include mobile {
    min-width: 10px;
    padding: 15px 20px;
  }

  &:hover {
    background-position: right center;
  }

  &:disabled {
    cursor: not-allowed;
    background-position: right center;
  }

  &:focus {
    outline: none;
  }

  &.is-small {
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 600;
  }
}
