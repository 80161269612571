h1 {
  font-weight: 900;
  font-size: 2.25rem;

  @include tablet {
    font-size: 1.5625rem;
  }
}

h2 {
  font-weight: 900;
  font-size: 1.875rem;

  @include tablet {
    font-size: 1.375rem;
  }
}

h3 {
  font-weight: 900;
  font-size: 1.25rem;

  @include tablet {
    font-size: 1.125rem;
  }
}

h4 {
  font-weight: 400;
  font-size: 1.125rem;

  @include tablet {
    font-size: 1rem;
  }
}

p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.55;

  @include mobile {
    font-size: 0.875rem;
  }
}

li {
  margin: 0.25rem 0.625rem;
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
p {
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
