@import '~/styles/styles';

.footer-container {
  display: grid;
  grid-auto-flow: column;
  place-content: space-evenly;
  background-color: $black;
  color: $white;
  min-height: $footer-height;

  @include mobile {
    padding: 20px;
    grid-auto-flow: row;
    grid-row-gap: 15px;
  }

  a,
  span {
    height: 18px;
    font-size: 12px;
    line-height: 1.7;
  }
}

.links-container {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 25px;
  place-content: center;
  text-align: center;

  @include mobile {
    &:last-child {
      display: grid;
      grid-auto-flow: unset;
      grid-column-gap: 12px;
      grid-template-columns: repeat(2, auto);

      a {
        order: 2;

        &:last-child {
          order: 1;
          grid-column: span 2;
        }
      }
    }
  }

  svg {
    width: 18px;
    height: 18px;
    fill: $gray;
    transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      fill: $white;
    }
  }
}
