@import '~/styles/styles';

.packs-list-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  width: 80vw;
  max-width: 1050px;
  margin: 0 auto;

  @include mobile {
    width: auto;
    grid-gap: 20px;
    padding: 0 30px;
  }
}

.pack-display-container {
  color: $white;
  text-align: center;
  width: 34vw;
  max-width: 400px;

  @include mobile {
    width: auto;
    max-width: unset;
  }

  .pack-type {
    text-transform: capitalize;
  }

  .torey-title {
    @include mobile {
      padding: 0;
    }
  }

  h2 {
    margin: 10px 0;
  }

  h3 {
    margin: 8px 0;
  }

  button {
    margin: 25px 0 0 0;

    @include mobile {
      font-size: 14px;
      padding: 15px 10px;
      width: 100%;
    }
  }
}

.packs-container {
  display: grid;
  grid-auto-flow: row;
  place-content: center;
  grid-gap: 40px;
  color: $white;
  margin: 0 auto;

  @include mobile {
    place-content: unset;
    padding: 0;
  }

  p {
    padding: 0 20px;
  }

  .packslist-copy-container {
    margin: 0 auto;
    text-align: center;
    max-width: 730px;

    h2 {
      margin-bottom: 20px;
    }
  }

  .is-small {
    padding: 0 10px;
  }
}

.tag-text {
  display: block;
  margin-bottom: -18.5px;

  @include mobile {
    font-size: 10px;
    margin-top: -12px;
    margin-bottom: -12px;
  }
}
