@import '~/styles/styles';

.container {
  display: grid;
  grid-auto-flow: column;
  gap: 3px;

  p {
    font-size: 14px;
    color: $gray;
  }
}

.link {
  cursor: pointer;
  font-weight: 400;
  color: $white;

  &:hover {
    text-decoration: underline;
  }
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.modal-content {
  height: 80vh;
  padding: 30px 40px;

  li {
    text-decoration: none;
  }
}

.modal-text-container {
  min-height: 100%;
  max-width: 80ch;
}

.text-center {
  text-align: center;
}
