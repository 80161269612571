@import '~/styles/styles';

.collectibles-container {
  display: grid;
  background-color: $black;
  aspect-ratio: 2.1;
  align-content: center;

  // TODO: Senior Greg look at this! Think of better inversion of control
  &.series-one {
    justify-content: flex-end;
    @include background-img($mikemoExplosionDesktop) {
      background-size: contain;
    }

    .collectibles-text-container {
      margin-right: 12vw;

      @include mobile {
        margin-right: 0;
        padding: 0 30px;
      }
    }

    @include mobile {
      background-size: 200%;
      height: unset;
      background-image: none;
      align-content: unset;

      &::before {
        content: '';
        display: block;
        width: 100vw;
        aspect-ratio: 0.75;
        @include background-img($mikemoExplosionDesktop) {
          background-size: 280%;
          background-position: 11% bottom;
        }
      }
    }
  }

  &.series-two {
    justify-content: center;

    @include background-img($seriesTwoBackground) {
      background-size: contain;
      background-position: right;
    }

    .collectibles-text-container {
      h4 {
        max-width: 350px;
      }
    }

    @include mobile {
      background-image: none;
      align-content: unset;
      &:before {
        content: '';
        display: block;
        width: 100%;
        aspect-ratio: 1.22;
        @include background-img($seriesTwoBackgroundMobile) {
          background-size: contain;
          background-position: right;
        }
      }
    }
  }

  &.quickstrike-tony-hawk {
    justify-content: flex-end;
    @include background-img($toreyBanner) {
      background-size: contain;
    }

    .collectibles-text-container {
      margin-right: 55vw;

      @include mobile {
        margin-right: 0;
        padding: 0 30px;
      }
    }

    @include mobile {
      background-size: 200%;
      height: unset;
      background-image: none;
      align-content: unset;

      &::before {
        content: '';
        display: block;
        width: 100vw;
        aspect-ratio: 0.75;
        @include background-img($toreyBanner) {
          background-size: 280%;
          background-position: 91% bottom;
        }
      }
    }
  }

  &.qs-jamie-foy {
    justify-content: flex-end;
    @include background-img($jamieFoyBanner) {
      background-size: contain;
    }

    .collectibles-text-container {
      margin-right: 55vw;

      @include mobile {
        margin-right: 0;
        padding: 0 30px;
      }
    }

    @include mobile {
      background-size: 200%;
      height: unset;
      background-image: none;
      align-content: unset;

      &::before {
        content: '';
        display: block;
        aspect-ratio: 0.8;
        @include background-img($jamieFoyBannerMobile) {
        }
      }
    }
  }

  &.qs-yeah-right {
    justify-content: flex-end;
    @include background-img($yeahRightBanner) {
      background-size: contain;
    }

    .collectibles-text-container {
      margin-right: 55vw;

      @include mobile {
        margin-right: 0;
        padding: 30px 30px;
        background-color: #a4d65e;
      }
    }

    @include mobile {
      background-size: 200%;
      height: unset;
      background-image: none;
      align-content: unset;

      &::before {
        content: '';
        display: block;
        aspect-ratio: 1.1;
        @include background-img($yeahRightBannerMobile) {
        }

      }
    }
  }
}

.collectibles-text-container {
  display: grid;
  max-width: 600px;
  min-width: 350px;
  width: 40vw;
  text-align: center;
  justify-items: center;
  grid-gap: 4px;

  @include mobile {
    margin: 0 0 40px 0;
    width: 100vw;
    padding: 0 30px;
  }

  h1 {
    margin-bottom: 8px;

    @include mobile {
      font-size: 24px !important;
    }
  }

  h4 {
    @include tablet {
      text-shadow: 0 0 3spx $black;
    }
  }

  button {
    margin-top: 25px;

    @include mobile {
      width: 100%;
      // max-width: 310px;
    }
  }
}

.email-button {
  width: 60%;

  @include mobile {
    width: 100%;
  }
}
