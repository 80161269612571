@import '~/styles/styles';

.main-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: $z-modal;
}

.modal-container {
  width: 100%;
  height: 100%;

  .modal {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    background: $black;
    z-index: $z-modal;

    @include mobile {
      position: fixed;
      top: 5px;
      transform: translate(-50%, 0);
      width: 90vw;
      min-height: 85vh;
    }

    &.has-rounded-corners {
      border-radius: 10px;
    }

    &.has-border-shadow {
      box-shadow: 0px 0px 5px 1px $gradient-purple-end;
    }

    .header {
      display: grid;
      height: 50px;
      place-content: center end;
    }

    .close-icon {
      border: 1px solid white;
      border-radius: 50%;
      position: relative;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        top: 50%;
        left: 50%;
        position: absolute;
        display: block;
        width: 70%;
        height: 2px;
        background-color: $white;
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($color: $gray-900, $alpha: 0.7);

  &.is-clickable {
    cursor: pointer;
  }

  canvas {
    width: 100%;
    height: 100%;
  }
}
