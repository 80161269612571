@import 'css-reset-and-normalize/css/reset-and-normalize.min.css';
@import './variable';
@import './mixins';
@import './typography';
@import './cta';
@import './animations';

html,
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  background-color: $black;
  color: $white;
  padding: calc($nav-height / 2) 0 0 0;

  &.lock-scroll {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}

.container {
  max-width: $lg;
  padding: 0 30px;
  margin: 0 auto;
}

.spinner-container {
  padding: 20px;
  line-height: 0;
}

.full-height {
  min-height: $content-height;

  @include mobile {
    min-height: $content-height-mobile;
  }
}
.place-center {
  display: grid;
  place-content: center;
}

.spinner {
  background-color: transparent;
  display: inline-block;
  margin: 0 auto;
  border: 2px solid $white;
  border-radius: 50%;
  border-bottom-color: transparent;
  width: 22px;
  height: 22px;
  animation: spinner 10s linear infinite;
}

// TODO: lock down these to one usage, preferably the uppercase ones to be easier to use with the data source
.commonGlow {
  text-shadow: $commonGlowShador;
  color: $commonGlowColor;
}
.CommonGlow {
  text-shadow: $commonGlowShador;
  color: $commonGlowColor;
}

.rareGlow {
  color: $rareGlowColor;
  text-shadow: $rareGlowShadow;
}
.RareGlow {
  // UX: Why are there two?
  color: $rareGlowColor;
  text-shadow: $rareGlowShadow;
}

.vaultedRareGlow {
  color: $vaultedRareGlowColor;
  text-shadow: $vaultedRareGlowShadow;
}
.vaultedrareGlow {
  color: $vaultedRareGlowColor;
  text-shadow: $vaultedRareGlowShadow;
}
.VaultedRareGlow {
  color: $vaultedRareGlowColor;
  text-shadow: $vaultedRareGlowShadow;
}

.legendaryGlow,
.legendaryRefractorGlow {
  color: $legendaryGlowColor;
  text-shadow: $legendaryGlowShadow;
}

.LegendaryGlow {
  color: $legendaryGlowColor;
  text-shadow: $legendaryGlowShadow;
}

// TODO: consolidate this with legendaryGlow
.legendary-text {
  color: $legendaryGlowColor;
  text-shadow: $legendaryGlowShadow;
}

.modalGlow {
  border: 2px solid #dadada;
  border-radius: 7px;
  outline: none;
}

.CommonModalGlow {
  border-color: $commonGlowColor;
  box-shadow: 0 0 10px $commonGlowColor;
  // Original here for ref, uses a different color though
  // border-color: #9ecaed;
  // box-shadow: 0 0 10px #9ecaed;
}

.RareModalGlow {
  border-color: $rareGlowColor;
  box-shadow: 0 0 10px $rareGlowColor;
}

.VaultedRareModalGlow {
  border-color: $vaultedRareGlowColor;
  box-shadow: 0 0 10px $vaultedRareGlowColor;
}

.LegendaryModalGlow,
.LegendaryRefractorModalGlow {
  border-color: $legendaryGlowColor;
  box-shadow: 0 0 10px $legendaryGlowColor;
}

.rainbow-text {
  background: $gradient-rainbow;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.margin-title {
  margin: 20px 0;
}

.margin-bottom {
  margin-bottom: 20px;
}

.bold {
  font-weight: bold;
}

.loading-dots {
  position: relative;
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 30px;
  transform: translateX(-990px);
  @include animate-dot(dot, 1000px);
  animation-delay: 200ms;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }

  &::before {
    @include animate-dot(dot2, 1015px);
    animation-delay: 500ms;
  }

  &::after {
    @include animate-dot(dot3, 1030px);
    animation-delay: 800ms;
  }
}

.gradient-button-legendary {
  padding: 10px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  background-image: linear-gradient(
    90deg,
    #fffca7 0%,
    #fffca7 0.01%,
    #f2ed45 101.49%
  );
  color: black;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  width: 200px;
  box-shadow: 0 0 7px #fbf9a9, 0 0 10px #cfcb49, 0 0 21px #969340;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  display: inline-block;
}

.legendary-button {
  display: grid;
  place-content: center;
  text-align: center;
  gap: 8px;
  margin: 20px 0px;

  @include mobile {
    margin: 10px 0;
  }
}

$gradient: linear-gradient(
  110deg,
  transparent 20%,
  rgba(94, 244, 228, 1) 30%,
  rgba(171, 84, 241, 1) 42%,
  // rgba(171, 84, 241, 1) 45%,
  rgba(235, 85, 176, 1) 66%,
  transparent 85%
);

.sweep {
  position: relative;
  display: inline;
  top: 0;
  left: 0;
  background: $gradient;
  background-size: 200% auto;
  background-position: 0% center;
  filter: brightness(1.4) contrast(1.05);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba($color: rgb(237, 231, 67), $alpha: 0.46);
  z-index: 1;
  line-height: normal;

  animation: holographic-animation 6.942s ease-out infinite reverse;

  &::after {
    content: attr(data-text);
    position: absolute;
    display: inline-block;
    left: 0;
    top: 0px;
    white-space: nowrap;
    text-shadow: 0 0 7px #fbf9a9, 0 0 21px #efed74;
    z-index: -1;
  }
}

.no-wrap {
  white-space: nowrap;
}

@keyframes holographic-animation {
  0% {
    background-position: 0% center;
  }
  50% {
    background-position: 200% center;
  }
  100% {
    background-position: 0% center;
  }
}

#nprogress {
  z-index: 100000000000;
  position: fixed;
  .spinner {
    display: none !important;
  }
}

.fire {
  margin: 0;
  color: rgb(255, 115, 0);
  text-shadow: 0 3px 20px red, 0 0 20px red, 0 0 10px orange,
    4px -5px 6px yellow, -4px -10px 10px yellow, 0 -10px 30px yellow;
  animation: 2s Blazing infinite alternate linear;
}

.smoke {
  animation: 2s smoke infinite alternate linear;
}

@keyframes Blazing {
  0% {
    text-shadow: 0 3px 20px red, 0 0 20px red, 0 0 10px orange, 0 0 0 yellow,
      0 0 5px yellow, -2px -5px 5px yellow, 4px -10px 10px yellow;
  }
  25% {
    text-shadow: 0 3px 20px red, 0 0 30px red, 0 0 20px orange, 0 0 5px yellow,
      -2px -5px 5px yellow, 3px -10px 10px yellow, -4px -15px 20px yellow;
  }
  50% {
    text-shadow: 0 3px 20px red, 0 0 20px red, 0 -5px 10px orange,
      -2px -5px 5px yellow, 3px -10px 10px yellow, -4px -15px 20px yellow,
      2px -20px 30px rgba(255, 255, 0, 0.5);
  }
  75% {
    text-shadow: 0 3px 20px red, 0 0 20px red, 0 -5px 10px orange,
      3px -5px 5px yellow, -4px -10px 10px yellow,
      2px -20px 30px rgba(255, 255, 0, 0.5), 0px -25px 40px rgba(255, 255, 0, 0);
  }
  100% {
    text-shadow: 0 3px 20px red, 0 0 20px red, 0 0 10px orange, 0 0 0 yellow,
      0 0 5px yellow, -2px -5px 5px yellow, 4px -10px 10px yellow;
  }
}

@keyframes smoke {
  0% {
    text-shadow: 0 1px 10px $gray-900, 0 0 20px $gray-900, 0 0 10px $gray-500,
      0 0 0 $gray, 0 0 5px $gray, 0 0 5px $gray, 0 0 10px $gray;
  }
  25% {
    text-shadow: 0 3px 20px $gray-900, 0 0 30px $gray-900, 0 0 20px $gray-500,
      0 0 5px $gray, -2px -5px 5px $gray, 3px -10px 10px $gray,
      -4px -15px 20px $gray;
  }
  75% {
    text-shadow: 0 3px 20px $gray-900, 0 0 20px $gray-900, 0 -5px 10px $gray-500,
      3px -5px 5px $gray, -4px -10px 10px $gray, 2px -20px 30px $gray,
      0px -35px 40px $gray-900;
  }
  100% {
    text-shadow: 0 3px 20px $gray-900, 0 0 20px $gray-900, 0 -5px 10px $gray-500,
      3px -5px 5px $gray, -4px -10px 10px $gray, 2px -40px 30px $gray,
      0px -65px 40px $gray-900;
  }
}

.button-container {
  margin: 50px 0;
  display: grid;
  place-content: center;
}

.pulsingButton {
  text-align: center;
  white-space: nowrap;
  display: block;
  box-shadow: 0 0 0 0 rgba(147, 61, 232, 0.7);
  background-color: #aa00ff;
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  text-decoration: none !important;
  color: #ffffff;
  transition: all 300ms ease-in-out;
  margin: 0 auto;
}

a.pulsingButton:hover {
  animation: none;
  color: #ffffff;
}

.pulsingButtonGreen {
  text-align: center;
  white-space: nowrap;
  display: block;
  box-shadow: 0 0 0 0 rgba(68, 214, 44, 1);
  background-color: #44d62c;
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  text-decoration: none !important;
  color: #ffffff;
  transition: all 300ms ease-in-out;
}

a.pulsingButtonGreen:hover {
  animation: none;
  color: #ffffff;
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

.centered {
  text-align: center !important;
}

.margin-auto {
  margin: 0 auto;
}

.margin-20 {
  margin: 20px 0;
}

.margin-10 {
  margin: 10px 0;
}

.info-icon {
  &::after {
    content: 'i';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border: 1px solid $white;
    border-radius: 50%;
    text-align: center;
    line-height: 19px;
    font-weight: 900;
    font-family: serif;
    vertical-align: middle;
    font-size: 12px;
    color: $white;
    background: $black;
    position: relative;
    top: -1px;
    left: -1px;
  }
}

.countdown-prod-pack {
  width: 25%;

  @include mobile {
    width: 100%;
    padding: 10px 0px;
  }
}

.countdown-pack-collection {
  margin: 0 auto;
  margin-bottom: 10px;
}

.element {
  height: 250px;
  width: 250px;
  margin: 0 auto;
  background-color: red;
  animation-name: stretch;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
  filter: blur(36px);
}

@keyframes stretch {
  0% {
    transform: scale(0.3);
    background-color: rgb(155, 155, 155);
    border-radius: 100%;
  }
  50% {
    background-color: rgb(84, 83, 83);
  }
  100% {
    transform: scale(1.5);
    background-color: rgb(175, 175, 175);
  }
}

.center {
  text-align: center;
}

.series3-tease-img {
  position: absolute;
  transform: translate(-50%, -5%);
  width: 25%;
  z-index: 9;
  filter: blur(20px);

  @include mobile {
    width: 75%;
  }
}

.mailchimp-width {
  width: 50%;
  margin: 0 auto;
  padding: 50px 0 100px;

  @include mobile {
    width: 80%;
    padding: 100px 0;
  }
}

.tv-and-bg {
  margin: 71px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-tb-40 {
  margin: 40px 0;
}

.header-container {
  width: 50%;
  margin: 20px auto;

  @include mobile {
    width: 100%;
  }
}

.container-lock {
  max-width: $lg;
  padding: 0 30px;
  margin: 0 auto;
}

button:focus-visible {
  border: 1px solid white;
}

.outline {
  background: transparent;
  border: 1px solid #4544ff;
}

.container-lock {
  .header-container {
    display: grid;
    gap: 20px;

    button {
      width: 50%;
      place-self: center;

      @include mobile {
        width: inherit;
      }
    }
  }
}

.hidden {
  visibility: hidden;
}

.margin-top-20 {
  margin-top: 20px;
}

.discord-button {
  color: black;
  background: #a1d55d !important;
}

.discord-button:hover {
  background: #44d62c !important;
  color: black;
}

.centered-button {
  margin: 0 auto;
}

.glitch-background-mobile {
  @include mobile {
    @include background-img($backgroundBigGlitch);
  }
}

.yr-header {
  text-shadow: 2px -1px 0 BLACK;
}

.yr-font {
  text-shadow: 1px -1px 0 BLACK;
  font-weight: bold;
  font-size: 20px;
  @include mobile {
    font-size: 18px;
  }
}

.yr-shadow {
  text-shadow: 1px 0px 0 BLACK;
}

.container-yr-header {
  width: 75%;
  text-align: center;
  margin: 0 auto;
  @include mobile {
    width: 100%;
  }
}

.bold {
  font-weight: bold;
}

.mb-10 {
  margin-bottom: 10px;
}

.left-align {
  text-align: left;
}

.nomargin {
  margin: 0 !important;
}

.hide-on-desktop {
  @media screen and (min-width: 700px) {
    display: none;
  }
}

.hide-on-mobile {
  @media screen and (max-width: 699px) {
    display: none;
  }
}

.yeah-right-background {
  background-color: #a1d55d ;
}

.mobile-fix {
  @include mobile {
    margin-top: 100px;
  }
}

.padding-yr {
  padding: 0 20px;
}

.container-rookie {
  font-family: 'Work Sans', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20%;
  font-size: 30px;
  font-weight: 700;
}

.reveal {
  overflow: hidden;
}

.reveal-first {
  margin: 0 10px;
}

.reveal__content {
  transform: translateY(100%);
  animation: reveal 1.7s cubic-bezier(1, 0, 0.3, 0.9) forwards;
}

.reveal:nth-child(2) .reveal__content {
  animation-delay: 0.6s;
}

.reveal:nth-child(3) .reveal__content {
  animation-delay: 1.2s;
}

@keyframes reveal {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.image-smaller {
  width: 40%;
  margin: 0 auto;

  @include mobile {
    width: 25%;
    margin: 0 auto;
  }
}

.link-color {
  color: #9f72ff;
  text-decoration: underline;
  font-weight: bold;
}

.rainbow-background {
  background: linear-gradient(90deg, #24665c 20%, #443e80 51%, #6b264f);
}

.berrics-montage-container {
  margin: 0 20px;
}

.yuto-mailchimp {
  margin: 0 20px;
}

.pt-30 {
  padding-top: 30px;
}


.berrics-margin {
  @include mobile {
    margin: 40px 20px 0px !important;
  }
}

.andy-padding {
  padding: 100px 0px;
}

.andy-header {
  font-size: 40px !important;

  @include mobile {
    font-size: 28px !important;
  }
}

.small-h1 {
  font-size: 14px;
}

.medium-h1 {
  font-size: 20px;
}

.large-h1 {
  font-size: 26px;
}

.andy-product-container {
  padding: 20px 0;
  text-align: center;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.for-mobile {
  display: none;
  @include mobile {
    display: unset;
  }
}

.for-desktop {
  display: unset;
  @include mobile {
    display: none;
  }
}

.pre-show-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px
}

.myspace-small {
  margin-right: 10px;
  width: 16px;
}

.pre-show-text {
  margin: 0;
}


.shiny-gold-button {
  background-color: #bd9732; /* Darker gold base color */
  background-image: linear-gradient(45deg, #d2ac4a, #ffdb70);
  border: none;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

.shiny-gold-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  filter: blur(15px);
  transform: skewX(-20deg);
  animation: spotlight 3s ease-in-out infinite;
}

@keyframes spotlight {
  0% {
    left: -150%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 150%;
  }
}

.shiny-gold-button:hover:before {
  animation: none;
}

.shiny-gold-button:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}
